import React, { Component } from "react";
import '../css/standard.css';

class footerBuilder extends Component {
    constructor(props) {
        super(props);
        this.state = {
            footerLinks: this.props.footerLinksArray
          };
    }
    render() {
        return (
            <div id='footer'>{this.renderFooterBox()}</div>
        );
    }

    renderFooterBox(){
        return(<div class='footerBox'>{this.renderFooterContent()}</div>)
    }

    renderFooterContent(){
        return(<div class='footerContent'>{this.renderFooterLinks()}</div>);
    }

    renderFooterLinks(){
        var links = [];
        for(var key in this.state.footerLinks){
            links.push(<a href={key} target='_blank' rel='noreferrer'><img src={this.state.footerLinks[key]} alt='footerLink'></img></a>);
        }
        return links;
    }
}

export default footerBuilder;