import React, { Component } from "react";
import $ from 'jquery';


class CitatFactory extends Component{
    constructor(props) {
        super(props);
        this.state = { 
           quote: {}
         };
    }

    render(){
        return(<p>{this.getRandomQuote()}</p>);
    }

    getRandomQuote(){
        return this.quoteObjToQuote(this.state.quote[this.getRandomNumber()]);
    }


    quoteObjToQuote(quoteObj){
        let quote = '"exampleQuote" - exmpleAuthor';
        if(quoteObj !== undefined){
            if(quoteObj['value'] !== undefined){
                quote = quote.replace('exampleQuote', quoteObj['value']);
            }
            if(quoteObj['author'] !== undefined){
                quote = quote.replace('exmpleAuthor', quoteObj['author']);
            }
        }
        return quote;
    }


    getRandomNumber() {
        return Math.floor(Math.random() * this.state.quote.length);
    }



/**
 * 
 * Code below is ONLY used to UPDATE state
 * 
 */
 componentDidMount() {
    this.getFile();
    setInterval(() => this.getFile(), this.props.time);
  }

 getFile(){
    $.getJSON('../files/quotes.json', this.readFile.bind(this));
}


readFile(file){
    this.setState({quote: file});
}

}

export default CitatFactory;