import React, { Component } from "react";
import mumbleIcon from '../../media/mumbleIco.png';
import redAFK from '../../media/redAFK.png';
import greenActive from '../../media/greenActive.png';
import '../../css/standard.css';
import '../../css/mumble.css';
import $ from 'jquery';

class MumbleWidget extends Component {
  constructor(props) {
    super(props);
    this.state = {
      channelDict: {},
    };
  }

  componentDidMount() {
    this.updateMumbleJSONList();
    setInterval(this.updateMumbleJSONList, this.props.time);
  }

  updateMumbleJSONList = () => {
    $.getJSON('https://rest.gavlegub.se/api/servers/mumble', this.readJsonList);
  }

  readJsonList = (jsonList) => {
    const currentChannelDict = { ...this.props.channelDict };
    jsonList.forEach(userObj => {
      const { key: username, value: channelName } = userObj;
      currentChannelDict[channelName] = [...(currentChannelDict[channelName] || []), username];
    });
    this.setState({ channelDict: currentChannelDict });
  }

  renderWidgetWrapper() {
    return (
      <div id='mumbleWidget' className='widgetHeader'>
        <img src={mumbleIcon} alt="Mumble Icon" />
      </div>
    );
  }

  getStatusImage(channelName) {
    return channelName === "AFK" || channelName === "Root" ? redAFK : greenActive;
  }

  renderChannel(channelName) {
    return (
      <div id='mumblePersonBox' className='mumblePersonBox'>
        <p><b>{channelName}</b></p>
      </div>
    );
  }

  renderPersons(channelName) {
    const personList = this.state.channelDict[channelName]?.map((element, index) => (
      <div key={index} id='mumblePersonBox' className='mumblePersonBox'>
        <img src={this.getStatusImage(channelName)} alt="Status Icon" />
        <p>{element}</p>
      </div>
    ));
    return personList || [];
  }

  renderPersonBoxes() {
    const personBoxList = [this.renderWidgetWrapper()];
    for (const key in this.state.channelDict) {
      personBoxList.push(this.renderChannel(key));
      personBoxList.push(this.renderPersons(key));
    }
    return personBoxList;
  }

  render() {
    return (
      <div id='mumbleUsersWidget' className='mumbleWidget'>
        {this.renderPersonBoxes()}
      </div>
    );
  }
}

export default MumbleWidget;