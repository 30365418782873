import React, { Component } from "react";
import '../../css/standard.css';
import '../../css/secretSanta.css';
import SecretSantaApp from "../widgets/secretSantaApp";

class GiftExchangeSite extends Component{
    constructor(props) {
        super(props);
        this.state = {
            leftPillar: null,
            rightPillar: null  };
    }

    render(){
        return(this.content());
    }

    content(){
        return(<div class='mainContent'><SecretSantaApp></SecretSantaApp></div>)
    }

}

export default GiftExchangeSite;
