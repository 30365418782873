import React, { Component } from 'react';

class RandomSmileyText extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inputText: this.props.inputText,
      resultText: "",
    };
  }

  smileyFaces = ["😀", "😁", "😂", "😃", "😄", "😅", "😆", "😉", "😊", "😋"];

  getRandomSmiley() {
    const randomIndex = Math.floor(Math.random() * this.smileyFaces.length);
    return this.smileyFaces[randomIndex];
  }

  render() {
    return (
      <div>
        <div>{this.getRandomSmiley()}{this.state.inputText}{this.getRandomSmiley()}</div>
      </div>
    );
  }
}

export default RandomSmileyText;