import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import '../../css/standard.css'
import { Link } from 'react-router-dom';

function navbar() {
  return (
    <Navbar expand="lg" class='navbar' >
      <Container> 
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav class='navbar'>
            <Nav.Link><Link to="/" className="nav-link">Gavlegub</Link></Nav.Link>
            <Nav.Link href="#communication">Kommunikation</Nav.Link>
            <Nav.Link href="#infromation">Information</Nav.Link>
            <NavDropdown title="LAN" bs-dropdown-bg="bs-pink">
              <NavDropdown.Item href="https://youtuberandom.gavlegub.se/">
                Youtube Randomizer
              </NavDropdown.Item>
              <NavDropdown.Item ><Link to="/GiftExchange">Juklappsbyte</Link></NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title="Servrar" bs-dropdown-bg="bs-pink">
              <NavDropdown.Item href="#action/3.1">AoE 2</NavDropdown.Item>
              <NavDropdown.Item href="#action/3.2">
                Minecraft
              </NavDropdown.Item>
              <NavDropdown.Item href="#action/3.3">Trackmania</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="#action/3.4">
                Separated link
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default navbar;