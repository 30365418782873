import React, { Component } from "react";
import HeaderBuilder from "./headerBuilder";
import FooterBuilder from './footerBuilder'
import Facebooklogo from '../media/facebookLogo.png';
import YoutubeLogo from '../media/youtubeLogo.png'
import '../css/standard.css';
import { Route, Routes } from "react-router-dom";
import BodyBuilder from "./bodyBuilder";

class SiteBuilder extends Component{
    constructor(props) {
        super(props);
        this.state = { 

         };
    }


    render(){
        return(
        <Routes>
            <Route path= "/" element={<div class='root'>{this.renderHeader()} {this.renderBody('Welcome')} {this.renderFooter()}</div>}/>
            <Route path= "/GiftExchange" element={<div class='root'>{this.renderHeader()} {this.renderBody('GiftExchange')} {this.renderFooter()}</div>}/>
        </Routes>
        );
    }

    renderBody(siteName){
        return(<BodyBuilder key={1} siteName={siteName}/>);
    }

    renderFooter(){
        var linkAndPictures = {'https://www.facebook.com/groups/438679819524143':Facebooklogo, 'https://www.youtube.com/user/gavlegub':YoutubeLogo};
        return(<FooterBuilder key={0} footerLinksArray={linkAndPictures} />)

    }

    renderHeader(){
        return(<HeaderBuilder />);
    }



}

export default SiteBuilder;