import React, { Component } from "react";
import gavgLogo from '../media/gavg.jpg';
import '../css/standard.css';
import CitatFactory from "./citatFactory";
import navbar from "./navbar/navbar";


class HeaderBuilder extends Component{
    constructor(props) {
        super(props);
        this.state = {  };
    }

    render(){
        return(<div id="includedContent">{this.renderHeaderWrapper()}</div>);
    }

    renderHeaderWrapper(){
        return <div class='headerWrapper'>{this.renderHeaderTop()} {this.renderNavbar()}</div>;
    }

    renderHeaderTop(){
        return <div name='aboveMenu' class='aboveMenu'>{this.renderLogo()} {this.renderQuote()}</div>;
    }


    renderLogo(){
        return <a href="/index.html"><img src={gavgLogo} name='gavglogo' alt="gavgLogo"></img></a> ;
    }

    renderQuote(){
        return <div id='citat' name='citat' class='citat'><CitatFactory key={1} time={15000}/></div> ;
    }

    renderNavbar(){
        return(
            navbar()
        );
    }


}

export default HeaderBuilder;