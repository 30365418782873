import React, { useState } from 'react';
import RandomSmileyText from './randomSmiley';

const SecretSantaApp = () => {
  const [names, setNames] = useState([]);
  const [assignments, setAssignments] = useState([]);
  const [newName, setNewName] = useState('');

  const handleNameChange = (e) => {
    setNewName(e.target.value);
  };

  const addName = () => {
    if (newName.trim() !== '') {
      if (!names.includes(newName)) {
        setNames([...names, newName]);
        setNewName('');
      } else {
        alert(newName + ' står ju redan med..');
      }
    }
  };

  const assignNames = () => {
    if (names.length < 2) {
      alert('En person på en JULKLAPPSUTDELNING? Ska fan klappa dig ja!.');
      return;
    }
  
    const shuffledNames = [...names];
    const assignedNames = [...shuffledNames];
  
    do {
      // Shuffle the names randomly
      for (let i = shuffledNames.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [shuffledNames[i], shuffledNames[j]] = [shuffledNames[j], shuffledNames[i]];
      }
  
      // Ensure that no person is assigned their own name
      let hasSelfAssignment = false;
      for (let i = 0; i < names.length; i++) {
        if (shuffledNames[i] === assignedNames[i]) {
          hasSelfAssignment = true;
          break;
        }
      }
  
      if (!hasSelfAssignment) {
        break;
      }
    } while (true);
  
    const newAssignments = names.map((name, index) => {
      return `${name} ska ge sin present till ${shuffledNames[index]}`;
    });
  
    setAssignments(newAssignments);
  };
  

  const resetApp = () => {
    setNames([]);
    setAssignments([]);
  };

  return (
    <div id="secretSantaWidgetWrapper">
      <div id="secretSantaWidgetHeader">
      <h1 class="threeDText">Gavlegubs Juklappsbyte 3.1</h1>
      </div>
      <div id="secretSantaWidgetContent">
        <h2 class="burning-text">Deltagare</h2>
        <ul>
          {names.map((name, index) => (
            <li key={index}> <RandomSmileyText inputText={name}></RandomSmileyText></li>
          ))}
        </ul>
        <input
          type="text"
          placeholder="Skriv namn"
          value={newName}
          onChange={handleNameChange}
        /><br></br>
        <button class="button" onClick={addName}>Plz Add</button>
        <button class="button" onClick={assignNames}>KBK!</button>
        <button class="button" onClick={resetApp}>Jag Glum</button>
      {assignments.length > 0 && (
        <div>
          <h2>Resultat</h2>
          <ul>
            {assignments.map((assignment, index) => (
              <li key={index}>{assignment}</li>
            ))}
          </ul>
        </div>
      )}
      </div>

    </div>
  );
};

export default SecretSantaApp;
