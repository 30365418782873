import React, { Component } from "react";
import PillarBuilder from "../pillarBuilder";
import '../../css/standard.css';
import MumbleWidget from "../widgets/mumbleWidget";

class WeclomeSite extends Component{
    constructor(props) {
        super(props);
        this.state = {
            leftPillar: null,
            rightPillar: null  };
    }

    render(){
        return(this.content());
    }

    content(){
        return(<div class='mainContent'><h1>Välkommen till Gavlegub</h1><p>Välkommen till Gavlegub - Gävles Spelförening!<br></br>

        Gavlegub är en dynamisk och engagerad spelförening baserad i hjärtat av Gävle, dedikerad till att skapa en gemenskap för spelentusiaster från alla hörn av staden och dess omgivningar. Vi är stolta över att vara en plats där spelintresserade individer kan samlas, dela sina passioner och uppleva den spännande världen av datorspel tillsammans.
        
        Vår förening är hem för alla typer av spelentusiaster, oavsett om du älskar att utforska fantasivärldar, tävla i eSports, eller bara koppla av med vänner över en omgång brädspel. Vi är övertygade om att spel är en fantastisk hobby som förenar människor och främjar gemenskap, och det är därför vi finns till för dig.
        
        Vad vi erbjuder:
        
        Spelgemenskap: Gavlegub är en plats där du kan träffa likasinnade människor och bygga varaktiga vänskapsband medan du delar dina spelupplevelser.
        
        Spelservrar: Vi är stolta över att erbjuda våra medlemmar dedikerade spelservrar för en mängd olika spel, så att du kan spela med vänner och andra medlemmar när du vill.
        
        LAN-evenemang: Vi arrangerar regelbundna LAN-partyn där du kan möta andra medlemmar personligen och spela dina favoritspel tillsammans. Det är en chans att uppleva den ultimata gaming-atmosfären.
        
        Oavsett om du är nybörjare eller en erfaren spelare, är du välkommen att ansluta dig till Gavlegub och vara en del av vår växande spelfamilj. Tillsammans kommer vi att utforska, tävla och ha kul i världen av spel.</p></div>)
    }

}

export default WeclomeSite;
