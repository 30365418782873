import React, { Component } from "react";
import '../css/standard.css';
import MumbleWidget from "./widgets/mumbleWidget";


class pillarBuilder extends Component {
    LEFT = 'LEFT';
    RIGHT = 'RIGHT'
    constructor(props) {
        super(props);
        this.state = {
            side: this.props.side,
            widgets: this.props.widgets
          };

    }
    
    render() {
        return (
            <div class={this.getSideClass()}>
            {this.renderWidgets()}
            </div>
            );
    }

    getSideClass(){
        if(this.props.side.toUpperCase() === this.LEFT){
            return('leftPillar');
        }else{
            return('rightPillar');
        }
    }

    renderWidgets(){
        if(this.state.widgets !== 'undefined'){
            var widgetList = [];
            this.state.widgets.forEach(widget => {
                widgetList.push(widget);
            });
            return(widgetList);
        }
        return;
    }
}

export default pillarBuilder;