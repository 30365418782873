import React, { Component } from "react";
import PillarBuilder from "./pillarBuilder";
import '../css/standard.css';
import MumbleWidget from "./widgets/mumbleWidget";
import WeclomeSite from "./sites/welcomeSite";
import GiftExchangeSite from "./sites/giftExchangeSite";

class BodyBuilder extends Component{
    constructor(props) {
        super(props);
        this.state = {
            leftPillar: null,
            rightPillar: null,  
            siteName: this.props.siteName
        };
    }

    render(){
        return(<div id="contentWrapper">{this.renderPillar(0, 'left', null)}{this.renderMainContent(this.props.siteName)}{this.renderPillar(1, 'right', <MumbleWidget key={1} time={3000}/>)}</div>);
    }

    renderPillar(id, side, widget){
        return(<PillarBuilder key={id} side={side} widgets={[widget]}/>);
    }

    renderMainContent(siteName){
        var bodyComponent;
        if(siteName === 'Welcome'){
            bodyComponent = <WeclomeSite></WeclomeSite>;
        }
        if(siteName === 'GiftExchange'){
            bodyComponent = <GiftExchangeSite></GiftExchangeSite>
        }
        return(bodyComponent);
    }

}

export default BodyBuilder;
